.controls {
  @apply mb-10;

  .controlGrid {
    @apply grid grid-cols-1 md:grid-cols-2 gap-4;

    .dateRangePickerContainer,
    .selectContainer {
      @apply text-left md:text-center;
    }
  }
}

.subtext {
  @apply text-gray-600;
}
