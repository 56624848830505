// Overriding the react calendar styles

.react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.react-datepicker__header {
  @apply bg-gray-100 border-b border-gray-400;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  @apply text-gray-600;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  @apply bg-green-400 text-white;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  @apply bg-green-300 text-white;
}

.react-datepicker__day--selected:not([aria-disabled='true']):hover,
.react-datepicker__day--in-selecting-range:not([aria-disabled='true']):hover,
.react-datepicker__day--in-range:not([aria-disabled='true']):hover,
.react-datepicker__month-text--selected:not([aria-disabled='true']):hover,
.react-datepicker__month-text--in-selecting-range:not(
    [aria-disabled='true']
  ):hover,
.react-datepicker__month-text--in-range:not([aria-disabled='true']):hover,
.react-datepicker__quarter-text--selected:not([aria-disabled='true']):hover,
.react-datepicker__quarter-text--in-selecting-range:not(
    [aria-disabled='true']
  ):hover,
.react-datepicker__quarter-text--in-range:not([aria-disabled='true']):hover,
.react-datepicker__year-text--selected:not([aria-disabled='true']):hover,
.react-datepicker__year-text--in-selecting-range:not(
    [aria-disabled='true']
  ):hover,
.react-datepicker__year-text--in-range:not([aria-disabled='true']):hover {
  @apply bg-green-400 text-white;
}

.react-datepicker__day--keyboard-selected {
  @apply bg-green-300 text-white;
}

.react-datepicker__day:hover {
  @apply bg-green-300 text-white;
}
